@import '~bootstrap/scss/_mixins';
@import 'Variables.scss';
@import 'Navigation.scss';
@import '~bootstrap/scss/bootstrap';

$base-color: #f0f1ef;
$paddingTopBottom: 50px;

body {
	font-family: 'Vazirmatn';
}

.container {
	max-width: 1000px;
}

.background-color-base {
	background-color: $base-color;
}

.container.normal {
	padding: 60px;
	padding-top: $paddingTopBottom;
	padding-bottom: $paddingTopBottom;
}

@media (max-width: 576px) {
	.container.normal {
		padding: 20px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.container.special {
	background-color: $base-color;
	padding: 60px;
	padding-top: $paddingTopBottom;
	padding-bottom: $paddingTopBottom;
	margin-top: 30px;
	margin-bottom: 30px;
}

@media (max-width: 576px) {
	.container.special {
		padding: 20px;
	}
}

.container.about {
	background-image: url('../../public/img/Julija_Hauser.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: -180px;
	padding: 60px;
	padding-top: $paddingTopBottom;
	padding-bottom: $paddingTopBottom;
	margin-top: 30px;
	margin-bottom: 30px;
}

@media (max-width: 992px) {
	.container.about {
		padding: 20px;
		background-image: none;
		background-position-x: -280px;
	}
}

@media (max-width: 576px) {
	.header-text {
		margin-top: 35px
	}
}

.who-am-i {
	background-color: $base-color;
	padding: 20px;
}

.zoom {
	transition: transform 0.2s;
}

.zoom:hover {
	transform: scale(1.1);
}

h1 {
	font-family: 'Alumni Sans';
	text-transform: uppercase;
	font-weight: 400;
	color: $primary;
	margin-bottom: 10px;
}

.bold-gold {
	font-weight: 600;
	color: $primary;
}

.bold {
	font-weight: 600;
}

.form-control {
	border: none;
}

.form-control.is-invalid {
	border: 1px solid red;
}

a:link,
a:visited,
a:hover,
a:active {
	transition: 0.5s;
	text-decoration: none !important;
	color: #b17f4c !important;
}

a:hover {
	color: #e3b381 !important;
}

.btn:active,
.btn:focus,
.btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

@media (max-width: 576px) {
	h1 {
		font-size: 2em;
	}
}

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css');
